import { SigninFailed, useSessionStore } from '@rentsales/shared'
import { DateTime } from 'luxon'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
      redirect: '/map',
      children: [
        {
          path: '/map',
          name: 'map',
          component: () => import('@/views/LeafletMapView.vue')
        },
        {
          path: '/address-details/:id?',
          name: 'address-details',
          component: () => import('@/views/AddressDetails.vue'),
          props: (route) => ({ id: route.params.id, createVisit: route.query.createVisit === 'true' })
        },
        {
          path: '/questionnaires',
          name: 'questionnaires',
          component: () => import('@/views/QuestionnairesView.vue'),
          props: (route) => ({ date: route.query.date ? DateTime.fromISO(route.query.date as string).toJSDate() : new Date() })
        },
        {
          path: '/absences',
          name: 'absences',
          component: () => import('@/views/AbsencesView.vue')
        },
        {
          path: '/visit/:id',
          name: 'visit',
          component: () => import('@/views/VisitView.vue'),
          redirect: { name: 'visit-overview' },
          children: [
            {
              path: 'overview',
              name: 'visit-overview',
              component: () => import('@/views/VisitOverviewView.vue'),
              props: true
            },
            {
              path: 'submissions',
              name: 'visit-submissions',
              component: () => import('@/views/VisitSubmissionsView.vue'),
              props: (route) => ({ id: route.params.id, navigateBackAfterCompletion: route.query.navigateBackAfterCompletion === 'true' })
            },
            {
              path: 'previous',
              name: 'visit-previous-visits',
              component: () => import('@/views/PreviousVisitsView.vue'),
              props: true
            },
            {
              path: 'previous/:visitId',
              alias: '/visit/:visitId/readonly',
              name: 'visit-previous-visit',
              component: () => import('@/views/PreviousVisitView.vue'),
              props: (route) => ({ id: route.params.visitId })
            }
          ]
        },
        {
          path: '/end-of-day/:date',
          name: 'end-of-day',
          component: () => import('@/views/EndOfDaySubmissionView.vue'),
          props: (route) => ({ date: DateTime.fromISO(route.params.date as string).toJSDate() })
        }
      ],
      meta: {
        authenticated: true
      }
    },
    {
      path: '/signin-redirect',
      beforeEnter: async () => {
        const sessionStore = useSessionStore()
        await sessionStore.initSession()
        return { name: 'home' }
      },
      component: () => {}
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import('@/views/SignInView.vue')
    },
    {
      path: '/signin-failed',
      name: 'signin-failed',
      component: SigninFailed
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, _from, next) => {
  const sessionStore = useSessionStore()
  // is authentication required
  if (to.matched.some((record) => record.meta.authenticated)) {
    if (!sessionStore.isAuthenticated) {
      next({ name: 'signin' })
      return
    }
  } else if (to.matched.some((record) => record.meta.anonymous) && sessionStore.isAuthenticated) {
    next({ name: 'home' })
    return
  }
  next()
})

export default router
