import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faBuilding,
  faCheck,
  faChevronRight,
  faCog,
  faEmptySet,
  faEnvelope,
  faFolder,
  faFolderOpen,
  faFolders,
  faGlobe,
  faLocationDot,
  faMagnifyingGlass,
  faMapLocationDot,
  faPaperPlane,
  faPencil,
  faSearch,
  faSitemap,
  faSubtitles,
  faTrash,
  faTreePalm,
  faUser,
  faUserCrown,
  faUsers,
  faVirus
} from '@fortawesome/pro-light-svg-icons'

export const icons = [
  faBars,
  faBuilding,
  faChevronRight,
  faCog,
  faEnvelope,
  faFolder,
  faFolderOpen,
  faFolders,
  faGlobe,
  faMagnifyingGlass,
  faMapLocationDot,
  faPencil,
  faSitemap,
  faSubtitles,
  faTrash,
  faTreePalm,
  faUser,
  faUserCrown,
  faUsers,
  faVirus,
  faPaperPlane,
  faCheck,
  faEmptySet,
  faSearch,
  faLocationDot
]

library.add(...icons)
